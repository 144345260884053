import { Application, registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const data: any = {
  loaders: {
    loader: `
    <style>
    .spinner-container{
      height:100vh;
      widht:100vw;
      background: #00ccff;
      display:flex;
      justify-content: center;
      align-items:center;
    }
    .lds-dual-ring {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    
  </style>
  <div class="spinner-container"> <div class="lds-dual-ring"></div></div>
    `,
  }
}


async function dynamicRouters() {
  const desktopRoutes = [
    {
      type: "route",
      default: true,
      routes: [{ type: "application", name: "@dibtech/microservice-dashboard", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "auth-login",
      routes: [{ type: "application", name: "@dibtech/dibauth", loader: data?.loaders?.loader }],
    },

    {
      type: "route",
      path: "user-management",
      routes: [{ type: "application", name: "@dibtech/user-management", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "pos-screen",
      routes: [{ type: "application", name: "@dibtech/pos-screen", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "ecommerce",
      routes: [{ type: "application", name: "@dibtech/ecommerce", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "website-builder",
      routes: [{ type: "application", name: "@dibtech/website-builder", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "forum",
      routes: [{ type: "application", name: "@dibtech/forum", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "blog",
      routes: [{ type: "application", name: "@dibtech/blog", loader: data?.loaders?.loader }],
    },

    {
      type: "route",
      path: "dib-attendance",
      routes: [{ type: "application", name: "@dibtech/dib-attendance", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "dynamicqrcode",
      routes: [{ type: "application", name: "@dibtech/dynamicqrcode", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "barcodeprinter",
      routes: [{ type: "application", name: "@dibtech/barcodeprinter", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "formbuilder",
      routes: [{ type: "application", name: "@dibtech/formbuilder", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "pushnotification",
      routes: [{ type: "application", name: "@dibtech/pushnotification", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "digital-signage",
      routes: [{ type: "application", name: "@dibtech/digital-signage", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "event-booking",
      routes: [{ type: "application", name: "@dibtech/event-booking", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "calendar",
      routes: [{ type: "application", name: "@dibtech/calendar", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "contact",
      routes: [{ type: "application", name: "@dibtech/contact", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "note",
      routes: [{ type: "application", name: "@dibtech/note", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "inventory",
      routes: [{ type: "application", name: "@dibtech/inventory", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "digital-marketing",
      routes: [{ type: "application", name: "@dibtech/digital-marketing", loader: data?.loaders?.loader }],
    }
  ];
  const mobileRoutes = [
    {
      type: "route",
      default: true,
      routes: [{ type: "application", name: "@dibtech/mobile-dashboard", loader: data?.loaders?.loader }],
    },
    {
      type: "route",
      path: "auth-login",
      routes: [{ type: "application", name: "@dibtech/dibauth", loader: data?.loaders?.loader }],
    },
  ]
  if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    let token = window.localStorage.getItem("token");
    const path = window.location.pathname;
    if (!token && path !== "/auth-login") {
      window.location.replace("/auth-login");
      return desktopRoutes; // Return early if already logged in
    }
    return desktopRoutes
  } else {
    let token = window.localStorage.getItem("token");
    const path = window.location.pathname;
    if (!token && path !== "/auth-login") {
      window.location.replace("/auth-login");
      return mobileRoutes; // Return early if already logged in
    }
    return mobileRoutes
  }
}

async function initialize() {
  const routes = await dynamicRouters();
  const routesConfig = constructRoutes({
    routes,
  });

  const applications = constructApplications({
    routes: routesConfig,
    loadApp: (params: { name: string }): Promise<Application<{}>> => {
      return System.import(params.name);
    }
  });

  const layoutEngine = constructLayoutEngine({
    routes: routesConfig,
    applications
  });

  applications.forEach(registerApplication);
  System.import("@dibtech/styleguide").then(() => {
    // Activate the layout engine once the styleguide CSS is loaded
    layoutEngine.activate();
    start();
  });
}
initialize();